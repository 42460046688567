import React from 'react';
import { PRODUCT_EGYPTIAN_COTTON_DUVET_COVER } from 'gatsby-env-variables';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import {
  ReviewBodySection,
  ReviewHeroSection,
} from '../../../components/common';
import { ReviewGrid } from '../../../types/component';
import { CountryCode, SiteUrls } from '../../../utils/constants';
import useGraphQLGetProduct from '../../../hooks/use-graphql-get-product';
import useGraphQLGetReviews from '../../../hooks/use-graphql-get-reviews';

const EgyptianCottonDuvetCoverReviewPage: React.FC = () => {
  const id = PRODUCT_EGYPTIAN_COTTON_DUVET_COVER;
  const product = useGraphQLGetProduct(id);
  const reviews = useGraphQLGetReviews(id);

  const data: ReviewGrid[] = reviews.map(review => {
    return {
      displayPic: review.user.displayPic,
      name: `${review.user.firstName} ${review.user.lastName}`,
      rating: review.rating,
      content: review.content,
      submittedOn: review.submittedOn,
    };
  });
  const title = 'Egyptian Cotton Duvet Cover';

  return (
    <Layout countryCode={CountryCode.SG}>
      <SEO
        title="Egyptian Cotton Duvet Cover Review Singapore"
        description="Woosa is company that prides itself with high quality bedroom products and services. Check some of our mattress reviews in Singapore here. "
      />
      <ReviewHeroSection
        type="EgyptianCottonDuvetCover"
        product={product}
        title={title}
      />
      <ReviewBodySection
        data={data}
        title={title}
        product={product}
        button={{
          text: 'Shop Egyptian Cotton Duvet Cover',
          url: SiteUrls.EgyptianCottonDuvetCover,
        }}
      />
    </Layout>
  );
};

export default EgyptianCottonDuvetCoverReviewPage;
